import React, { useEffect, useState } from 'react';
import '../../App.css';

const VisitCounter = ({ path }) => {
  const [count, setCount] = useState(null);

  useEffect(() => {
    // 调用后端 API 获取指定路径的访问量
    const fetchCount = async () => {
      try {
        const response = await fetch(`https://www.jummmpdesign.cn/api/visit-count?path=${path}`);
        const data = await response.json();
        setCount(data.count);
        console.log("Visit count:", data.count);
      } catch (error) {
        console.error("Error fetching visit count:", error);
      }
    };
    fetchCount();
  }, [path]);

  return (
    <div>
      <div className='blogCaption'>Views: {count !== null ? count : "加载中..."}</div>
    </div>
  );
};

export default VisitCounter;
