import logo from '../../svg/JUMMMPDESIGN.svg';
import styles from './Navigator.module.css';
import { useNavigate } from 'react-router-dom';
import backsvg from './back.svg';
import React from 'react';

function Navigator({ hasBackButton = false }) {

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // 传递状态到上一页
  };

  return (
    <div className={styles.Navigator}>
      {hasBackButton &&  <img src={backsvg} onClick={handleBackClick} alt="Back"  className={styles.backButton}/>}
      {!hasBackButton && <img src={logo} className={styles.WebLogo} alt="" />}
        
    </div>
  );
}

export default Navigator;
