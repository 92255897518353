import styles from './Chip.module.css';
import '../../App.css';

function Chip({ text }) { 
    return (
      <div className={styles.ChipContainer}>   
        <div class="blogText"> {text} </div>
      </div>
    );
}

export default Chip;
