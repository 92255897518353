import styles from './Spacer.module.css';
import '../../App.css';

function Spacer({ value }) { // 解构 value 作为 props
    return (
      <div className={styles.spacer60} style={{ height: value }}>   
      </div>
    );
}

export default Spacer;
