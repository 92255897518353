import React, { createContext, useContext, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home/Home';
import DetailPage from './pages/DetailPage/DetailPage';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DIA from './svg/DIA.svg';
import NoReadyImg from './img/noReady.jpg';
import Spacer from './components/Spacer/Spacer';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [tabState, setTabState] = useState('1'); // 默认 Tab 为 'tab1'
  
  return (
    <AppContext.Provider value={{ tabState, setTabState }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/detail" element={testDetail()} /> 
        <Route path="/TouchDiffusion" element={touchDiffusionDetail()} />
        <Route path="/Photon" element={photonDetail()} />
        <Route path="/EyeSay" element={eyeSayDetail()} />
        <Route path="/Where" element={whereDetail()} />
        <Route path="/FunFine" element={funFineDetail()} />
        <Route path="/LeftLife" element={leftLifeDetail()} />
        <Route path="/IntuitModeling" element={intuitModelingDetail()} />
        <Route path="/FusionProtor" element={fusionProtorDetail()} />
        <Route path="/IEDS" element={IEDSDetail()} />
        <Route path="/SocializeChat" element={socializeChatDetail()} />
        <Route path="/VRGesture" element={VRGestureDetail()} />
        <Route path="/Xining" element={XiningDetail()} />
        <Route path="/Qingshanhu" element={QingshanhuDetail()} />
        <Route path="/Faxisi" element={FaxisiDetail()} />
        <Route path="/HybridProtype" element={hybridProtypeDetail()} />
      </Routes>
    </BrowserRouter>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



function touchDiffusionDetail() {
  var introduction = 
  <div className='column'>
    <div class='blogText'>
      TouchDiffusion 是一款基于 ESP 硬件平台和 Unity 引擎开发的智能创作系统。通过拼接磁吸智能积木和语音交互，用户可以轻松地创建具有物理模型连接结构的虚拟模型，并基于此生成图片和带有纹理材质的 3D 模型，实现简单、快速和低门槛的 3D 创作。
    </div>
    <Spacer value="20px"></Spacer>
    <div class='blogTitle'>
      实体组件
    </div>
    <div class='blogText'>
    TouchDiffusion以构建世界的三种基础形状作为起点，立方体、圆柱体和三棱柱。每个形状都配备了自主研发的磁吸接口，可在不同形状的不同面、以不同朝向随意组合连接，带来无限自由的拼装体验。
    </div>
    <Spacer value="20px"></Spacer>
    <div class='blogTitle'>
      硬件开发
    </div>
    <div class='blogText'>
      我们基于ESP硬件平台，设计并开发了磁吸智能积木。得益于ESP32-PICO-D4芯片迷你的体型和紧凑的封装，天线、电源、通讯和其他模块集成在这片小小的电路板中，让积木的微处理器能够轻松驱动分布式计算和无线通讯。
    </div>
    <video width="100%" controls className="video" poster="https://www.jummmpdesign.cn/images/TouchDiffusion_Chip.jpg">
        <source src="https://www.jummmpdesign.cn/videos/TouchDiffusion_Chip.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
    <div class='blogText'>
      我们还特别设计了一款磁吸充电座。这款充电座巧妙地集成了充电模块，轻轻一吸，就可以为智能积木无线磁吸充电，或者让多个积木堆叠同时充电，让创作流畅进行而不中断。
    </div>
    <Spacer value="20px"></Spacer>
    <div class='blogTitle'>
      软件开发
    </div>
    <div class='blogText'>
      每当用户连接手中的积木时，积木会发出包含连接信息的无线通讯信号，这个信号被系统解析为图连通数据结构，进而构建出和用户手中积木形状一致的虚拟模型。值得一提的是，实体积木被转化为数字模型的整个流程都是实时的。
    </div>
    <Spacer value="20px"></Spacer>
    <div class='blogTitle'>
      AI算法
    </div>
    <div class='blogText'>
      这套系统搭载了团队自主探索的3D生成式人工智能算法链路，使得TouchDiffusion能够根据虚拟模型和提示词、生成带有精美纹理材质的3D模型，实现简单、快速和低门槛的3D创作。
    </div>
    <video width="100%" controls className="video" poster="https://www.jummmpdesign.cn/images/TouchDiffusion_AIpipeline.jpg">
        <source src="https://www.jummmpdesign.cn/videos/TouchDiffusion_AIpipeline.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
    <Spacer value="20px"></Spacer>
    <div class='blogTitle'>
      未来畅想
    </div>
    <div class='blogText'>
      用户只需举起并双击套件，用自然语言描述创作需求，即可在LLM（大语言模型）的帮助下轻松完成积木上色、提示词撰写、3D 生成等任务。这种直观、简便的交互方式，让 TouchDiffusion 对儿童也同样友好，即使不会使用鼠标键盘，也能轻松上手。
    </div>
    <video width="100%" controls className="video" poster="https://www.jummmpdesign.cn/images/TouchDiffusion_agent.jpg">
        <source src="https://www.jummmpdesign.cn/videos/TouchDiffusion_agent.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
  </div>
    
  return <DetailPage
      type={1}
      title="TouchDiffusion"
      time="2024 June"
      videoSrc={"https://www.jummmpdesign.cn/videos/touchdiffusion.mp4"}
      awardImg={DIA}
      awardYear={"2024"}
      awardText1={"中国高校计算机大赛智能交互创新赛"}
      awardText2={"最具创意奖"}
      videoPosterImage={"https://www.jummmpdesign.cn/images/touchdiffusion1.jpg"}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/touchdiffusion1.jpg",
        "https://www.jummmpdesign.cn/images/touchdiffusion2.jpg",
        "https://www.jummmpdesign.cn/images/touchdiffusion4.jpg",
        "https://www.jummmpdesign.cn/images/touchdiffusion5.jpg",
        "https://www.jummmpdesign.cn/images/touchdiffusion6.jpg",
        "https://www.jummmpdesign.cn/images/touchdiffusion7.jpg",
        "https://www.jummmpdesign.cn/images/touchdiffusion3.jpg"
      ]
      }
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#honor', title: 'HONOR 荣誉' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["2024 | 中国智造设计大奖（DIA） 设计新锐奖","2024 | 中国高校计算机大赛智能交互创新赛 最具创意奖"]} // 可以传入多个荣誉,"2024 | 中国研究生“美丽中国”创新设计大赛 国家级二等奖"
      thanks="此项目为个人本科毕业设计。感谢博士后陈培对本项目的辅导与支持，博士生周子洪、方宇阳在硬件工程方面的指导和帮助，硕士生谢学泷在AI模型和服务器远程部署部分给予的指导，博士生张宏博在设计应用和研究思想方面给予的指导和帮助，课题组实习生杨静雯在硬件组装方面提供的支持与帮助。 感谢浙江大学国际设计研究院在研究经费、开发器材和工作环境方面提供支持。" // 致谢内容
  />
}

function photonDetail() {
  var introduction = 
    <div class='blogText'>
      这是一款画风卡通、可爱温馨、操作简单的探索游戏。玩家扮演光精灵，在唯美的童话世界里自由自在的飞行探索，探索隐藏区域，寻找更多光子。过程中你还会遇到各种奇奇怪怪、可可爱爱的生物，同时也要小心别被黑洞吞噬。游戏目前一共有6个关卡，分为洞穴、森林和湖泊三个大场景，通关时间约为30分钟。游戏已上线 Tap Tap，欢迎<a href='https://www.taptap.cn/app/724373'><b>下载</b></a>游玩。
    </div>;

  return <DetailPage
      type={1}
      title="Photon | 光子"
      time="2024 October"
      videoSrc={"https://www.jummmpdesign.cn/videos/Photon.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      videoPosterImage={"https://www.jummmpdesign.cn/images/Photon1.jpg"}
      galleryImages={[
          "https://www.jummmpdesign.cn/images/Photon1.jpg",
          "https://www.jummmpdesign.cn/images/Photon2.jpg",
          "https://www.jummmpdesign.cn/images/Photon3.jpg",
          "https://www.jummmpdesign.cn/images/Photon4.jpg",
          "https://www.jummmpdesign.cn/images/Photon5.jpg",
          "https://www.jummmpdesign.cn/images/Photon6.jpg",
        ]
      }
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#honor', title: 'HONOR 荣誉' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["暂无"]} 
      thanks="本游戏为与海未起明游戏工作室共同合作的项目。感谢海未起明游工作室在技术开发、美术资产、游戏运营等方面提供大力支持。" // 致谢内容
  />
}

function hybridProtypeDetail() {
  var introduction = 
    <div class='blogText'>
      概念设计是设计过程中的关键阶段，其最终成功在很大程度上依赖于设计师的创造力。物理原型和数字原型常被设计师用来支持构思和创意，分别提供直观的感知和快速迭代。随着技术进步，大规模生成模型能够通过生成与人类设计师相媲美的高质量解决方案，提供数据驱动的创意支持。这为设计师打开了一个想象空间，并为设计工具带来了新可能性。在本研究中，我们提出了一种在概念设计阶段将物理模型与生成式人工智能（AI）协同融合的混合原型方法，并开发了相应的混合原型系统以实现该方法。我们开展了一项对比用户研究，邀请了45位设计师分别使用物理原型方法、独立的生成式AI以及混合原型方法完成设计任务。结果验证了混合原型方法的有效性，并探讨了其在支持创造力方面的机制。最后，我们讨论了混合原型方法的应用价值和优化空间。
    </div>;

  return <DetailPage
      type={2}
      title="A Hybrid Prototype Method Combining Physical Models and Generative Artificial Intelligence to Support Creativity in Conceptual Design"
      time="2024 September"
      // videoSrc={"https://www.jummmpdesign.cn/videos/touchdiffusion.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      // videoPosterImage={TouchDiffusionImage}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/HybridPrototype1.jpg"
      ]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#publication', title: 'PUBLICATIONS  发表成果' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["Hongbo Zhang, Pei Chen, Xuelong Xie, Zhaoqu Jiang, Zihong Zhou, and Lingyun Sun. 2024. A Hybrid Prototype Method Combining Physical Models and Generative Artificial Intelligence to Support Creativity in Conceptual Design. ACM Transactions on Computer-Human Interaction (2024). https://doi.org/10.1145/3689433"]} // 可以传入多个荣誉,"2024 | 中国研究生“美丽中国”创新设计大赛 国家级二等奖"
      thanks="本研究的第一学生作者为博士生张宏博，感谢他的全力付出。感谢博士后陈培对本研究的支持与帮助，硕士生谢学泷在AI模型和服务器远程部署部分的参与。 感谢浙江大学国际设计研究院在研究经费、开发器材和工作环境方面提供支持。" // 致谢内容
  />
}

function whereDetail() {
  var introduction = 
    <div class='blogText'>谓尔，一款全方位对用户本我内在进行数字孪生，在虚拟现实中建立虚拟自我，以获得即时正向反馈来实现自我提升的人格映射“养成型”系统——引导用户在现实生活中订立目标，经过细粒度用户画像与性格情绪可视化技术实现对用户内在本质的数字孪生虚拟身份，提供虚拟世界行业生态的接入口。</div>;

  return <DetailPage
      type={1}
      title="Where | 谓尔"
      time="2023 April"
      videoSrc={"https://www.jummmpdesign.cn/videos/where.mov"}
      // awardImg={DIA}
      awardYear={"2023"}
      awardText1={"“互联网+”大学生创新创业大赛"}
      awardText2={"全国季军"}
      videoPosterImage={"https://www.jummmpdesign.cn/images/Where1.jpg"}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/Where3.jpg",
        "https://www.jummmpdesign.cn/images/Where2.jpg"

      ]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#honor', title: 'HONOR 荣誉' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["2023 | 全国第十三届挑战杯大学生创业计划竞赛 国家级金奖","2023 | 全国“互联网+”大学生创新创业大赛 国家级金奖、全国季军"]}
      thanks="谓尔 App 的最初想法由王振阳、李想和徐瑞柏（排名不分先后）提出，王振阳将谓尔作为创业项目继续推进。感谢浙江大学启真交叉学科创新创业实验室（X-Lab）的大力支持。感谢挑战杯比赛全体成员的倾情奉献：王振阳、陈珂涵、蒋招衢、蔡燕妃、缪可嘉、潘瑞哲、傅琳、章峥、滕佳琪、舒婧。感谢“互联网+”比赛的全体成员的倾情奉献：潘瑞哲、缪可嘉、黄睿哲、马润生、赵紫宸、傅琳、王雯宇、宋薇杰、姚苏洋、
全心阳、陈珂涵、蒋招衢、王雨桐、谢杨畅。" // 致谢内容
  />
}

function fusionProtorDetail() {
  var introduction = 
    <div class='blogText'>
      在概念设计中，开发和模拟3D原型对于构思和展示至关重要。物理原型和虚拟原型各具优势，是概念设计过程中不可或缺的组成部分。然而，传统方法通常将这两种领域分离，导致原型工作流程割裂。此外，无论是物理还是虚拟，要获取高保真原型和模拟往往耗时且资源密集，分散了设计师在创意探索方面的注意力。

    在此背景下，我们提出了FusionProtor，这是一种用于概念设计中实现快速组件级物理-虚拟3D转换与模拟的混合原型工具。它结合了生成式人工智能（GAI）进行组件级生成。基于生成的独立组件，FusionProtor支持根据物理运动逻辑进行动态交互模拟。同时，它还利用增强现实（AR），使设计师能够将物理和虚拟组件混合用于融合构思和展示。

    我们进行了技术和用户实验，以验证FusionProtor在支持多样化设计方面的稳健性和可用性。结果表明，FusionProtor实现了从物理到虚拟、从低保真到高保真的无缝工作流程，提升了效率并促进了创意构思。此外，我们对其最佳实践进行了深入讨论，并探讨了混合交互对设计的影响，以期为HCI领域带来启发。
    </div>;

  return <DetailPage
      type={2}
      title="FusionProtor: A Mixed-Prototype Tool for Component-level Physical-to-Virtual 3D Transition and Simulation in Conceptual Design"
      time="In Progress"
      videoSrc={"https://www.jummmpdesign.cn/videos/FusionProtor.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      videoPosterImage={"https://www.jummmpdesign.cn/images/FusionProtor7.jpg"}
      galleryImages={Array.from({ length: 7 }, (_, i) => `https://www.jummmpdesign.cn/images/FusionProtor${i + 1}.jpg`)}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#publication', title: 'PUBLICATIONS  发表成果' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["本研究正在投稿中"]}
      thanks="本研究的第一学生作者为博士生张宏博，感谢他的全力付出。感谢博士后陈培对本研究的支持与帮助，硕士生谢学泷在AI模型和服务器远程部署部分的参与。 感谢浙江大学国际设计研究院在研究经费、开发器材和工作环境方面提供支持。" // 致谢内容
  />
}

function funFineDetail() {
  var introduction = 
    <div class='blogText'>
      社会面临原子化趋势，人与人线下接触不足，因此经常产生社交匮乏感。面对两三百人的微信列表，人们经常感到“我们朋友不够多”，在一些需要朋友的时刻却找不到朋友聊天。
      这背后问题，一方面在于，列表好友并不是真朋友。人们之间的社交网络被各种app简化列表好友，以至于人们似乎误认为加好友本身便是建立社交联系。微信列表和好朋友两个概念存在不同，但是线上的社交模式模糊了这里的差别，让人们误以自己“朋友”不少从而弱化了社交意愿。
      另一方面，列表线上互动不能取代真实的把拉近的联系。线上聊天对于维持关系人际交往来说是不完整的，而人们往往有时候意识不到这一点，在朋友圈点赞评论之后也对现实接触产生了不确定的畏惧，也不好意思发起线下互动邀约。
      本款微信小程序“饭饭之交”旨在提供更立体生动的互动方式，旨在建立良好用户社交习惯，拉近人和人距离。
    </div>;

  return <DetailPage
      type={1}
      title="FunFine | 饭饭之交"
      time="2022 May"
      videoSrc={"https://www.jummmpdesign.cn/videos/FunFine.mov"}
      // awardImg={DIA}
      awardYear={"2022"}
      awardText1={"靳埭强设计奖-UI赛道"}
      awardText2={"入围奖"}
      videoPosterImage={ "https://www.jummmpdesign.cn/images/FunFine1.jpg"}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/FunFine1.jpg",
        "https://www.jummmpdesign.cn/images/FunFine2.jpg"
      ]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#honor', title: 'HONOR 荣誉' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["2022 | 微信小程序应用开发赛 华东赛区二等奖","2022 | 靳埭强设计奖（UI赛道） 入围奖"]} 
      thanks="感谢浙江大学启真交叉学科创新创业实验室（X-Lab）设计部交互组的大力支持。" // 致谢内容
  />
}

function FaxisiDetail() {
  var introduction = 
    <div class='blogText'>在法喜寺，踏入这片宁静的空间，心中自有一份安定。古老的香火缭绕，仿佛能洗净心灵的尘埃。我站在佛前，双手合十，心中默念着那些期盼与祝福。庙宇的静谧使人忘却喧嚣，仿佛时间也在这一刻凝固。轻声祈祷，愿身边的亲友安康，愿自己的心灵能找到那份宁静的归属。在这里，仿佛一切烦恼都被慢慢吞噬，留下的只有对未来的希望与对生活的感悟。法喜寺，带给我的是一种朴素而真切的祝福，让我在这喧嚣的世界中，感受到内心的平和。</div>;

  return <DetailPage
      type={3}
      title="祈福纳祥"
      time="2024 September"
      // videoSrc={"https://www.jummmpdesign.cn/videos/touchdiffusion.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      // videoPosterImage={TouchDiffusionImage}
      galleryImages={Array.from({ length: 3 }, (_, i) => `https://www.jummmpdesign.cn/images/Faxisi${i + 1}.jpg`)}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#honor', title: 'HONOR 荣誉' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={[]} 
      thanks="感谢安安的陪同。" // 致谢内容
  />
}

function QingshanhuDetail() {
  var introduction = 
    <div class='blogText'>正午的杭州青山湖，阳光毫不吝惜地洒在水面上，湖水如镜般将蓝天与群山揽入怀中。微风拂过，层层碧波荡漾，光影交织，像在述说着一种宁静的力量。此时的青山湖，没有清晨的朦胧，也没有傍晚的柔情，只有一份明亮而坚定的沉稳。站在湖畔，感受到阳光透过树影洒在身上，仿佛内心的阴翳也被驱散，取而代之的是一种温暖的充实。正午的湖光让人明白，生活中的安然与自在，或许就在这如昼般的坦荡之中，悄然融入每一刻的平凡时光。</div>;

  return <DetailPage
      type={3}
      title="青山湖循迹"
      time="2024 Augest"
      // videoSrc={"https://www.jummmpdesign.cn/videos/touchdiffusion.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      // videoPosterImage={TouchDiffusionImage}
      galleryImages={Array.from({ length: 18 }, (_, i) => `https://www.jummmpdesign.cn/images/Qingshanhu${i + 1}.jpg`)}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={[]} 
      thanks="感谢黄铸衍的出境。" // 致谢内容
  />
}

function XiningDetail() {
  var introduction = 
    <div class='blogText'>西北的风总是很粗狂，玫瑰从未在这片土地生长。如果你喜欢浪漫，这里也并非寸草不生。</div>;

  return <DetailPage
      type={3}
      title="西宁以西"
      time="2024 Augest"
      videoSrc={"https://www.jummmpdesign.cn/videos/Xining.mov"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      videoPosterImage={"https://www.jummmpdesign.cn/images/Xining1.jpg"}
      galleryImages={Array.from({ length: 24 }, (_, i) => `https://www.jummmpdesign.cn/images/Xining${i + 2}.jpg`)}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={[]} // 可以传入多个荣誉,"2024 | 中国研究生“美丽中国”创新设计大赛 国家级二等奖"
      thanks="感谢滕朱瑜、方宇阳、徐云楷、潘正华、裘妩双的出镜，我们一起在青海和甘肃度过了愉快的时光。部分照片由徐云楷提供。感谢金石徐开提供的无人机设备。" // 致谢内容
  />
}

function intuitModelingDetail() {
  var introduction = 
    <div class='blogText'>
      帮助新手和非专业用户高效完成3D建模任务仍然是人机交互（HCI）领域的一项重大挑战。为了解决这一问题，我们提出了IntuitModeling，一个可触摸的3D建模系统，使用户能够直观且轻松地创建具有复杂表面结构的3D模型。IntuitModeling包含一系列灵活可变形的触觉交互组件，这些组件以各种基本几何形状呈现，可以通过自然变形实现多样化的复杂表面结构。我们在每个交互组件内嵌入了一种特定布局的惯性测量单元（IMU）传感器网络，能够将其物理形状实时重构为数字3D模型。用户只需用手操作这些组件，便可以快速创建不同的基本几何模型及其具有复杂表面的变体。此外，我们开发了一条定制化的设计流程，以支持不同形状的可变形组件的快速定制，使其具有自我形状感知功能。用户实验表明，IntuitModeling使新手和非专业用户能够简单、直观、高效地完成复杂的3D建模任务。
    </div>;

  return <DetailPage
      type={2}
      title="IntuitModeling: Intuitively Tangible 3D Modeling via Multiform and Flexible Self-Shape-Sensing Devices"
      time="In Progress"
      // videoSrc={"https://www.jummmpdesign.cn/videos/touchdiffusion.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      // videoPosterImage={TouchDiffusionImage}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/IntuitModeling1.jpg",
        "https://www.jummmpdesign.cn/images/IntuitModeling2.jpg"
      ]}
      anchorItems={[
        { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
        { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
        { key: 'part-3', href: '#publication', title: 'PUBLICATIONS  发表成果' },
        { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
    ]}
      introduction={introduction}
      honors={["本研究正在投稿中"]}
      thanks="本研究的第一学生作者为博士生周子洪，感谢他的全力付出。感谢博士后陈培对本研究的支持与帮助。 感谢浙江大学国际设计研究院在研究经费、开发器材和工作环境方面提供支持。" // 致谢内容
  />
}

function IEDSDetail() {
  var introduction = 
    <div class='blogText'>概念设计是产品开发中的重要阶段，受设计空间和可用材料的影响。人机交互（HCI）和人工智能（AI）技术的进步显著拓宽了这些方面。一方面，增强现实（AR）技术融合了物理和虚拟表征，增强了直观交互和具身认知。另一方面，生成式人工智能（GAI）作为一种新颖的设计材料，提升了创意和生产力。受这些技术进步的启发，我们提出了智能具身设计空间（IEDS），该空间整合了设计师、AR 和 GAI，通过结合具身交互与生成变异性来支持概念设计。在 IEDS 中，设计师可以直观地与物理原型互动，而 GAI 则将这些转化为可通过 AR 技术嵌入物理世界的虚拟形式。在本研究中，我们通过文献综述和专家访谈建立了 IEDS 的理论框架和交互模式，随后开发了三个 IEDS 系统，以实际检验这些交互模式在不同设计任务中的优势。我们通过批判性讨论总结了 IEDS 对概念设计的影响，并向 HCI 社区展望了其未来愿景。</div>;

  return <DetailPage
      type={2}
      title="IEDS: Exploring an Intelli-Embodied Design Space combining designer, AR, and GAI to support conceptual design"
      time="In Progress"
      // videoSrc={"https://www.jummmpdesign.cn/videos/touchdiffusion.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      // videoPosterImage={TouchDiffusionImage}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/IEDS1.jpg",
        "https://www.jummmpdesign.cn/images/IEDS2.jpg"
      ]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#publication', title: 'PUBLICATIONS  发表成果' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["本研究正在投稿中"]}
      thanks="本研究的第一学生作者为博士生张宏博，感谢他的全力付出。感谢博士后陈培对本研究的支持与帮助，硕士生谢学泷在AI算法部分的参与。 感谢浙江大学国际设计研究院在研究经费、开发器材和工作环境方面提供支持。" // 致谢内容
  />
}

function VRGestureDetail() {
  var introduction = 
    <div class='blogText'>
      概念设计是设计过程中的基础阶段。传统的概念设计工具对3D对象的直观创作过程存在限制，阻碍了其全部潜力的发挥。在混合现实（MR）中应用基于手势的交互提供了一种沉浸式、直观的创作模式，无需维度转换，有助于在3D概念设计中构思创意。尽管已有关于手势语言的研究从多种个体角度探讨了不同的手势设计方法和技术，但缺乏对概念设计场景中手势应用的整体理解和全面建议。本研究旨在建立一套适用于MR环境下3D概念设计的认知、物理和系统层面的手势语言。我们在MR环境中进行了两阶段的研究，结合了引导设计和综合评估实验。通过引导设计，收集了约930个手势动作，涵盖了31个目标功能。通过评估实验，提出了一套理论上最优的3D概念设计手势交互语言，并明确了相应的手势交互指南。我们的研究结果可以进一步拓展MR环境中的人机交互，并为软件开发者提供灵感，开发新颖的手势驱动交互模式或工具。
    </div>;

  return <DetailPage
      type={2}
      title="Elicitation and Evaluation of Hand-based Interaction Language for 3D Conceptual Design in Mixed Reality"
      time="2023 November"
      // videoSrc={"https://www.jummmpdesign.cn/videos/touchdiffusion.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      // videoPosterImage={TouchDiffusionImage}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/VRGesture1.jpg",
        "https://www.jummmpdesign.cn/images/VRGesture3.jpg"
      ]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#publication', title: 'PUBLICATIONS  发表成果' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["Lingyun Sun, Hongbo Zhang, Pei Chen, Zhaoqu Jiang, Xuelong Xie, Zihong Zhou, Xuanhui Liu, Xiaoyu Chen, Elicitation and Evaluation of Hand-based Interaction Language for 3D Conceptual Design in Mixed Reality, International Journal of Human-Computer Studies (2024), https://doi.org/10.1016/j.ijhcs.2023.103198"]} 
      thanks="本研究的第一学生作者为博士生张宏博，感谢他的全力付出。感谢博士后陈培对本研究的支持与帮助，硕士生谢学泷在AI算法部分的参与。 感谢浙江大学国际设计研究院在研究经费、开发器材和工作环境方面提供支持。" // 致谢内容
  />
}

function socializeChatDetail() {
  var introduction = 
    <div class='blogText'>
      运动和言语障碍人士的沟通能力有限，因而高度依赖增强和替代沟通（AAC）工具。现有的商业AAC工具提供固定词语的简单组合，能够满足基本的生理需求，但在社会沟通方面面临巨大挑战。这种社交沟通对用户的心理健康尤为重要，尤其是对于运动能力有限的人士。因此，本文开发了SocializeChat，这是一款利用大型语言模型（LLM）技术并结合注视输入的AAC工具，旨在增强社交聊天功能。具体来说，SocializeChat能够实时生成多条对话句子，提供符合用户话题偏好的建议，并根据对话中人物的关系调整句子的表达风格。此功能通过包含内容偏好和社交亲密度的用户数据集以及精心设计的提示和流程实现。在简短测试中，SocializeChat被评价为能够有效体现用户的内容偏好和沟通风格。
    </div>;

  return <DetailPage
      type={2}
      title="SocializeChat: a GPT-based AAC Tool for Social Communication Through Eye Gazing"
      time="2023 October"
      // videoSrc={"https://www.jummmpdesign.cn/videos/touchdiffusion.mp4"}
      // awardImg={DIA}
      // awardText1={"某某比赛的名字"}
      // awardText2={"全国一等奖"}
      // videoPosterImage={TouchDiffusionImage}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/SocializeChat1.jpg"
        // "https://www.jummmpdesign.cn/images/touchdiffusion2.jpg",
        // "https://www.jummmpdesign.cn/images/touchdiffusion3.jpg",
        // "https://www.jummmpdesign.cn/images/touchdiffusion4.jpg",
        // "https://www.jummmpdesign.cn/images/touchdiffusion5.jpg",
        // "https://www.jummmpdesign.cn/images/touchdiffusion6.jpg",
        // "https://www.jummmpdesign.cn/images/touchdiffusion7.jpg"
      ]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#publication', title: 'PUBLICATIONS  发表成果' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["Yuyang Fang, Yunkai Xu, Zhuyu Teng, Zhaoqu Jiang, and Wei Xiang. 2023. SocializeChat: a GPT-based AAC Tool for Social Communication Through Eye Gazing. In Adjunct Proceedings of the 2023 ACM International Joint Conference on Pervasive and Ubiquitous Computing & the 2023 ACM International Symposium on Wearable Computing (UbiComp/ISWC '23 Adjunct), 128–132. https://doi.org/10.1145/3594739.3610705"]} 
      thanks="感谢方宇阳、徐云楷、滕朱瑜对这项研究工作的投入和贡献。感谢向为老师的指导。" // 致谢内容
  />
}

function leftLifeDetail() {
  var introduction = 
  <div className='column'>
     <div class='blogText'>
      我们团队在期待一款游戏，它能够从剧情和艺术的角度出发，树立源于生活的哲学镜像，通过融合游戏的玩法，带来关于人生抉择、思考的沉浸体验。
      游戏中艾米丽的人物原型源于一个纪录片——记者在赌场采访了一个头发已经花白的老妇人。受记者和老妇人谈话的启发，我们团队不禁思考：在这样一个每日赌博的老人身上，背后会有什么样的苦衷和故事。这为我们的游戏题材提供了一个契机，我们团队便将她的人生故事进行解析、构建，抽象成一本具有六个主题日记，分别对应着死亡、伪幸福、破碎、封闭、离别与婚礼，幻化成日记中转站的六扇门。
      在玩法上，我们创新了游戏形式。在剧情场景中，艾米丽向赌博投射了诸多希冀，期望能迎来生活压力的解脱和救赎，但周遭人的误解却让她背负污名化的枷锁，她的亲人、朋友都幻化成了她的心魔，让希望变成绝望、绝望变成动荡，一步步推动剧情的发展。在战斗场景中，基于传统的塔防玩法，我们融入带有赌博意象的“斗地主”卡牌玩法，希望将艾米丽自殁于赌博的情状渗透进游戏的每时每刻，达到了剧情和玩法的高度统一。
      我们和艾米丽一样相信：“你当铭记，愿望，应深埋心底。”在世人残余的惊悸里，艾米丽正寻找人生中真挚的爱，迎来逆境的突围。
     </div>
    <Spacer value="20px"></Spacer>
    <div class='blogText'>
      《余生Left Life》是一款将塔防、卡牌、剧情三者相结合的2D游戏。在孙女杰西卡的记忆中，祖母艾米丽一直是一个很神秘的人。在一次搬家的过程中，杰西卡偶然间翻开了祖母生前留下的日记，不知不觉中进入了由记忆片段构成的虚幻空间……
      在游戏中，玩家将进入祖母艾米丽的日记世界，通过操控杰西卡探索祖母生前的故事，通过放置卡牌、召唤精灵，击溃祖母内心的梦魇。五十岁突然去世的祖母，生前究竟发生过什么样的故事？在充满记忆片段和怪物的日记世界中，杰西卡又会经历什么……
      在剧情场景中，玩家通过方向键控制角色移动，按下空格键触发对话。
      在战斗场景中，玩家通过抽取获得强化卡1～10或精灵卡J/Q/K。在塔防玩法中，拖动精灵卡至放置点可以召唤精灵，强化卡1-5可强化精灵射程，6-10可强化伤害。在卡牌玩法中，玩家可以按照斗地主规则（如对子、三带一、顺子等等）将强化卡组合打出，造成全场伤害，重复或递增的牌越多，伤害越高。与扑克不同的是，游戏中的卡牌最小是1，最大是K，所以游戏中的顺子和连对的判定略有差异。
      玩家可采取卡牌、塔防或二者混合的策略。游戏的成就系统让关卡具有复玩性。游戏剧情兼具故事性和人生思考。
    </div>
  </div>
    

  return <DetailPage
      type={1}
      title="Left Life | 余生"
      time="2022 October"
      videoSrc={"https://www.jummmpdesign.cn/videos/LeftLife.mp4"}
      // awardImg={DIA}
      awardYear={"2022"}
      awardText1={"腾讯高校游戏创意制作大赛"}
      awardText2={"优胜奖"}
      videoPosterImage={"https://www.jummmpdesign.cn/images/LeftLife6.jpg"}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/LeftLife1.jpg",
        "https://www.jummmpdesign.cn/images/LeftLife2.jpg",
        "https://www.jummmpdesign.cn/images/LeftLife3.jpg",
        "https://www.jummmpdesign.cn/images/LeftLife4.jpg",
        "https://www.jummmpdesign.cn/images/LeftLife5.jpg"
      ]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#honor', title: 'HONOR 荣誉' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["2022 | 腾讯高校游戏创意制作大赛 优胜奖"]}
      thanks="感谢团队成员周麟铭、王龙强、刘昱含、王序、吴潇琳、蒋招衢的倾情奉献（以上排名不分先后）。" 
  />
}

function eyeSayDetail() {
  var introduction = 
    <div class='blogText'>EyeSay是一款基于眼动交互的重度失能人群人格化辅助沟通工具。EyeSay的亮点如下：（1）低负荷眼动交互输入：采用基于ARKit眼动追踪技术的易用且低负荷的输入方式，有效降低用户使用沟通工具的门槛。通过追踪算法和界面优化，实现了低负荷较为准确的眼动输入。（2）人格化情感辅助表达：利用ChatGPT语言对话模型、语音识别和生成技术，为失能人群提供情感化的需求表达和聊天沟通。相较于传统的固定按钮式辅助表达工具，这种技术能更好地满足患者、护理者和家属之间的情感需求，让身体失能的患者在精神上不失能。（3）普惠性兼顾成本与效果：EyeSay运行于苹果移动设备，具备原深感摄像头（iPhoneX及其后续机型）即可。它基于ARKit眼动追踪功能，进行了追踪算法和交互界面优化，实现了低成本的眼动输入，无需昂贵眼动仪，让更多人受益。</div>;

  return <DetailPage
      type={1}
      title="EyeSay | 眼语"
      time="2023 September"
      videoSrc={"https://www.jummmpdesign.cn/videos/EyeSay.mp4"}
      // awardImg={DIA}
      awardYear={"2023"}
      awardText1={"移动应用创新赛"}
      awardText2={"全国二等奖"}
      videoPosterImage={"https://www.jummmpdesign.cn/images/EyeSay1.jpg"}
      galleryImages={[
        "https://www.jummmpdesign.cn/images/EyeSay7.jpg",
        "https://www.jummmpdesign.cn/images/EyeSay3.jpg"
      ]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#honor', title: 'HONOR 荣誉' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["2023 | 中国高校计算机大赛移动应用创新赛 国家级二等奖、社会责任创新奖"]} 
      thanks="EyeSay为团队作品，感谢团队成员滕朱瑜、方宇阳在中国高校计算机大赛移动应用创新赛的大力投入，徐云楷在视频制作、论文成果转化方面的倾情奉献。以上排名均不分先后。" // 致谢内容
  />
}

function testDetail() {
  var introduction = 
    <div class='blogText'>"这是作品的详细介绍，包含相关信息和描述。"</div>;

  return <DetailPage
      type={1}
      title="作品名称"
      time="2024 June"
      videoSrc={"/"}
      videoPosterImage={NoReadyImg}
      galleryImages={[NoReadyImg, NoReadyImg, NoReadyImg]}
      anchorItems={[
          { key: 'part-1', href: '#introduction', title: 'INTRODUCTION 介绍' },
          { key: 'part-2', href: '#gallery', title: 'GALLERY 图库' },
          { key: 'part-3', href: '#honor', title: 'HONOR 荣誉' },
          { key: 'part-4', href: '#thanks', title: 'THANKS 致谢' },
      ]}
      introduction={introduction}
      honors={["2024 | 拭目以待", "2023 | 拭目以待"]} // 可以传入多个荣誉
      thanks="感谢所有支持我的人。" // 致谢内容
  />
}
