import styles from './DetailPage.module.css';
import '../../App.css'
import Navigator from '../../components/Navigator/Navigator';
import React, { useState, useEffect } from 'react';
import Spacer from '../../components/Spacer/Spacer';
import { ConfigProvider, Anchor, Row, Image, Space } from 'antd';
import leftWheat from '../../svg/leftWheat.svg';
import rightWheat from '../../svg/rightWheat.svg';
import VisitCounter from '../../components/VisitCounter/VisitCounter';
import {
    DownloadOutlined,
    LeftOutlined,
    RightOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
  } from '@ant-design/icons';
  
function DetailPage({ type, title, time, awardImg, awardYear, awardText1, awardText2, videoSrc, videoPosterImage, galleryImages, anchorItems, url,
    introduction, // 作品介绍
    honors,       // 所获荣誉
    thanks        // 致谢人员
}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
    const [isOneColumn, setIsOneColumn] = useState(window.innerWidth <= 1240);
    const currentPath = window.location.pathname;
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
            setIsOneColumn(window.innerWidth <= 1240);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleAnchorClick = (e, link) => {
        e.preventDefault(); // 阻止默认行为，避免 URL 中出现锚点
        scrollToElement(link.href.replace('#', '')); // 手动滚动到目标元素
    };

    const [showAll, setShowAll] = useState(false);

    const handleShowMore = () => {
        setShowAll(true);
    };

    // 根据 type 参数判断显示的标题
    const getTypeTitle = () => {
        switch (type) {
            case 1:
                return "Project / 项目";
            case 2:
                return "Research / 研究";
            case 3:
                return "Photograph / 纪录";
            case 4:
                return "About / 关于";
            default:
                return "Unknown Type";
        }
    };

    return (
        <div>
            <Navigator hasBackButton={true} />

            <Spacer value="120px" />
            <Row>
                <div className="grid-container">
                    <div className="column grid-item-3" id="introduction">
                        <div class={isOneColumn?"tipBox grid-item-1":"tipBox grid-item-3"}>
                            <div class='blogText'> 🚧 网站建设中，详情页的当前内容并非最终版本。</div>
                        </div>
                        <div className={isOneColumn ? "grid-item-1" : "grid-item-3"}>
                            <div className='blogTitle'>{getTypeTitle()}</div>
                            <div class='blogWebTitle'>{title}</div>
                            <div class='blogLargeCaption'>{time}</div>
                        </div>



                        {(awardImg||awardText1) && <Spacer value="20px" />}

                        {<div className={styles.AwardContainer}>
                            {awardImg && 
                                <div>
                                    <img src={awardImg} className={styles.AwardImg} alt='' />
                                </div>
                            }
                            {awardText1 &&
                                <div className={styles.WheatContainer}>
                                    <img src={leftWheat} className={styles.Wheat} alt='' />
                                    <div className={styles.AwardtextContainer}>
                                        <div className='blogCaption'>{awardYear}</div>
                                        <div className='blogText'>{awardText1}</div>
                                        <div className='blogLargeTitle'>{awardText2}</div>
                                    </div>
                                    <img src={rightWheat} className={styles.Wheat} alt='' />
                                </div>
                            }
                        </div>}
                        <Spacer value="30px" />

                        


                        
                        <div class='blogLargeTitle'>INTRODUCTION / 介绍</div>
                        {
                            videoSrc &&
                            <video width="100%" controls className="video" poster={videoPosterImage}>
                                <source src={videoSrc} type="video/mp4" />
                                <source src={videoSrc} type="video/quicktime" />
                                Your browser does not support the video tag.
                            </video>
                        }
                        
                        <div>{introduction}</div>

                        <Spacer value="30px" />

                        <div class='blogLargeTitle' id="gallery">GALLERY / 图库</div>
                        
                        <div className="Gallery-container">
                        <Image.PreviewGroup
                            preview={{
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onZoomOut,
                                            onZoomIn,
                                        },
                                    }
                                ) => (
                                    <Space size={40} className={styles["toolbar-wrapper"]}>
                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                    </Space>
                                )
                            }}
                        >
                        {showAll ? (
                            galleryImages.map((img, index) => (
                                <div className="GalleryImage" key={index}>
                                    <Image
                                        src={img}
                                        alt=""
                                        placeholder={
                                            <Image
                                                preview={false}
                                                src={img.replace('images', 'imagesPlaceholder')} // 替换路径
                                                width={200}
                                            />
                                        }
                                    />
                                </div>
                            ))
                        ) : (
                            <>
                                {galleryImages.slice(0, 6).map((img, index) => (
                                    <div className="GalleryImage" key={index}>
                                        <Image
                                            src={img}
                                            alt=""
                                            placeholder={
                                                <Image
                                                    preview={false}
                                                    src={img.replace('images', 'imagesPlaceholder')} // 替换路径
                                                    width={200}
                                                />
                                            }
                                        />
                                    </div>
                                ))}
                                {galleryImages.length > 6 && (
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                        <div className={styles.more} onClick={handleShowMore}>
                                            查看更多
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        </Image.PreviewGroup>
                    </div>
                        
                        <Spacer value="30px" />
                        {type === 2 && 
                            <div>
                                <div class='blogLargeTitle' id="publication">PUBLICATIONS / 发表成果</div>
                                <ul>
                                    {honors.map((honor, index) => (
                                        <li key={index}>
                                            <div className='blogText'>{honor}</div> {/* 使用 props 中的 honors */}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                        {type === 1 && 
                            <div>
                                <div class='blogLargeTitle' id="honor">HONOR / 所获荣誉</div>
                                <ul>
                                    {honors.map((honor, index) => (
                                        <li key={index}>
                                            <div className='blogText'>{honor}</div> {/* 使用 props 中的 honors */}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                        

                        <Spacer value="30px" />
                        <div class='blogLargeTitle' id="thanks">THANKS / 致谢</div>
                        <div class='blogText'>{thanks}</div>

                        
                        <div class="Copyright">
                            <VisitCounter path={currentPath}></VisitCounter>
                            © Zhaoqu Jiang 2024. All rights reserved.
                        </div>
                    </div>
                    <div className="fixed-column grid-item-1" style={{ display: isMobile ? "none" : "" }}>
                        <ConfigProvider theme={{ components: { Anchor: { colorPrimary: '#333333' } } }}>
                            <Anchor items={anchorItems} onClick={handleAnchorClick} />
                        </ConfigProvider>
                    </div>
                </div>
            </Row>
        </div>
    );
}

export default DetailPage;
