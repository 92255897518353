import styles from './ProjectCard.module.css';
import '../../App.css'
import { useNavigate } from 'react-router-dom'; // 如果使用 React Router

function ProjectCard({ img, title, time, awardImg, isLong, isMobile, intro, url="detail"}) {
  // 计算高度
  const height = isLong ? (isMobile ? '340px' : '490px') : (isMobile ? '300px' : '414px');
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url);
  };

  return (
    <div className={styles.CardContainer} class="grid-item-2">
      <div className={styles.CardImgContainer} style={{ height: height }}>
          <img src={img} className={styles.CardImg} onClick={handleClick} style={{cursor:"pointer"}} alt={title}/>
          {awardImg && <img src={awardImg} className={styles.CardAward} alt='' />}
      </div>
      <div className={styles.CardTitle}>
        <div style={{display:"inline",cursor: "pointer"}} onClick={handleClick}>{title}</div>
      </div>
      <div className={styles.CardTime}>
        <div style={{display:"inline",cursor: "pointer"}} onClick={handleClick}>{time}</div>
      </div>

      {intro && <div className={styles.CardIntro}>{intro}</div>}
      
    </div>
  );
}

export default ProjectCard;